import React from 'react';
import { styled } from '@mui/system';
import BlurPaper from '../../components/dumps/BlutPaper';
import { useParams } from 'react-router-dom';
import { ModelProvider, useModel } from '../../components/providers/ModelProvider';
import GoldButton from '../../components/dumps/GoldButton';
import MainMobilePage from '../../components/layout/MainMobilePage';
import TelegramIcon from '@mui/icons-material/Telegram';
import ModelName from '../../components/containers/model/ModelName';
import ModelAvatar from '../../components/containers/model/ModelAvatar';
import ExternalLink from '../../components/dumps/ExternalLink';
import ModelPrices from '../../components/containers/ModelPrices';
import SubscribeButton from '../../components/containers/model/SubscribeButton';
import VideoCallButton from '../../components/containers/model/VideoCallButton';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
// scroll
/*
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '0.5em',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '0.5em',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
*/
}));

const ModelMessageLink = () => {
  const { model } = useModel();
  return (
    <ExternalLink href={model?.botLink || ''}>
      <GoldButton
        fullWidth
        variant='contained'
        disabled={!model?.botLink}
        startIcon={<TelegramIcon fontSize='large' />}
      >
        Message
      </GoldButton>
    </ExternalLink>
  );
}

const ModelPage: React.FC = () => {
  const { alias } = useParams<{ alias: string }>();
  return (
    <ModelProvider alias={alias as string}>
      <MainMobilePage>
        <Root>
          <BlurPaper duration='0.25s'>
            <ModelAvatar />
            <ModelName />
            <ModelMessageLink />
            <SubscribeButton />
            <VideoCallButton />
          </BlurPaper>
          <ModelPrices />
        </Root>
      </MainMobilePage>
    </ModelProvider>
  );
};

export default ModelPage;