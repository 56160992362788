import cfg from '../config';
import { TokenBox } from '../types/token-box';

export const utrls = {
  List: '/api/v1/token-boxes',
};

export const list = async (): Promise<TokenBox[]> => {
  const response = await fetch(`${cfg.apiUrl}${utrls.List}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json()
}
