import React from 'react';;
import { Typography, TypographyProps } from '@mui/material';
import { useModel } from '../../providers/ModelProvider';

type ModelNameProps = TypographyProps;
const ModelName: React.FC<ModelNameProps> = (props) => {
  const { model } = useModel();
  return (
    <Typography variant='h5' fontWeight={700} {...props}>
      {model?.name}
    </Typography>
  );
}

export default ModelName;