import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  BottomNavigation as MuiBottomNavigation,
  BottomNavigationAction as MuiBottomNavigationAction,
  Typography,
  styled
} from '@mui/material'
import useVibrate from '../../lib/useVibrate'
import { useNavigate, useLocation } from 'react-router-dom';
import { isTMA, retrieveLaunchParams, requestViewport } from '@tma.js/sdk';
import MineIcon from '../dumps/icons/MineIcon';
import TapIcon from '../dumps/icons/TapIcon';
import FriendsIcon from '../dumps/icons/FriendsIcon';
import SettingsIcon from '../dumps/icons/SettingsIcon';
import { Toaster } from "react-hot-toast";
import { Link } from 'react-router-dom';

const Root = styled('div')<{ vheight?: number }>(({ theme, vheight }) => ({
  //position: 'absolute',
  //left: 0,
  //top: 0,
  //right: 0,
  //bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  //height: short ? '94dvh' : '100dvh',
  // height: '100%',
  height: '100vh',
  //height: vheight ? `${vheight}px` : '100%',
  overflowY: 'auto',
}))

/*
const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: 'calc(100% + 1px)',
  maxHeight: 'calc(100% + 1px)',
  overflow: 'hidden',
}))
*/

const Header = styled('div')(({ theme }) => ({
  width: '90%',
  position: 'absolute',
  top: 0,
}))

const Main = styled('main')<{ header?: boolean, headerHeight?: number, fullSize?: boolean }>(({ theme, header, headerHeight, fullSize }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  // flexGrow: 1,
  width: fullSize ? '100%' : '90%',
  //height: vheight ? `${vheight}px` : (fullSize ? '100%' : 'calc(100% - 56px - 12px)'),
  // The current height of the visible area of the Mini App. Also available in CSS as the variable var(--tg-viewport-height).
  // overflow: 'hidden',
  paddingTop: header ? theme.spacing(headerHeight || 21) : theme.spacing(fullSize ? 0 : 2),
  paddingBottom: theme.spacing(fullSize ? 0 : 12),
}))

const BottonContainer = styled('div')<{ bottom?: number }>(({ theme, bottom = 0 }) => ({
  width: '100%',
  position: 'absolute',
  bottom: bottom + theme.spacing(1),
  display: 'flex',
  justifyContent: 'center',
}))

const BottomNavigation = styled(MuiBottomNavigation)(({ theme }) => ({
  width: '90%',
  borderRadius: theme.spacing(1.5),
  backgroundColor: theme.palette.background.paper,
}))

const BottomNavigationAction = styled(MuiBottomNavigationAction)(({ theme }) => ({

}))

const BottomNavigationLabel = styled(Typography)(({ theme }) => ({
  fontSize: 10,
}))

const unselectedColor = '#5F6368'
const selectedColor = '#FFB800'

export type MobilePageProps = {
  title?: string,
  children?: React.ReactNode,
  hideBottomNav?: boolean,
  fullSize?: boolean,
  headerHeight?: number,
  header?: React.ReactNode,
}

const MobilePage = ({ header, headerHeight, children, hideBottomNav, fullSize }: MobilePageProps) => {
  const { vibrate } = useVibrate()
  const navigate = useNavigate()
  const handleMenuChange = useCallback((event: React.ChangeEvent<{}>, newValue: string) => {
    navigate(newValue)
    vibrate(20)
  }, [navigate])

  //const [bottomOffset, setBottomOffset] = useState<number>(0)
  //const [viewportHeight, setViewportHeight] = useState<number | undefined>()
  const rootRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    isTMA().then(async (isTMA) => {
      if (isTMA) {
        const lp = retrieveLaunchParams();

        // Some versions of Telegram don't need the classes above.
        if (['macos', 'tdesktop', 'weba', 'web', 'webk'].includes(lp.platform)) {
          return;
        }
        
        const v = await requestViewport();
        if(window.innerHeight !== v.height) {
          //setViewportHeight(v.height);
          //setBottomOffset(window.innerHeight - v.height);
        }
      }
    }).catch((error) => {
      console.error('MyApp isTMA error', error);
    });
  }, [rootRef]);


  const location = useLocation();
  const current = location.pathname;

  //vheight={viewportHeight}
  return (
    <Root ref={rootRef}>
      {/* vheight={viewportHeight} fullSize={fullSize} */}
      <Main header={!!header} fullSize={fullSize} headerHeight={headerHeight}>
        {children}
      </Main>
      {header && (
        <Header>
          {header}
        </Header>
      )}
      {/* bottom={bottomOffset} */}
      <BottonContainer
        sx={{
          display: hideBottomNav ? 'none' : 'flex'
        }}
      >
        <BottomNavigation showLabels onChange={handleMenuChange}>
          <BottomNavigationAction
            label={<BottomNavigationLabel>Models</BottomNavigationLabel>}
            icon={<TapIcon htmlColor={current === '/' ? selectedColor : unselectedColor} />}
            value='/'
            LinkComponent={Link}
          />
          <BottomNavigationAction
            label={<BottomNavigationLabel>Subs</BottomNavigationLabel>}
            icon={<FriendsIcon htmlColor={current === '/subs' ? selectedColor : unselectedColor} />}
            value='/subs'
            LinkComponent={Link}
          />
          <BottomNavigationAction
            label={<BottomNavigationLabel>Settings</BottomNavigationLabel>}
            icon={<SettingsIcon htmlColor={current === '/additional' ? selectedColor : unselectedColor} />}
            value='/additional'
            LinkComponent={Link}
          />
        </BottomNavigation>
      </BottonContainer>
      <Toaster toastOptions={{ position: "top-center" }} />
    </Root>
  )
}

export default MobilePage
