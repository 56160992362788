import React from "react";
import { FormControl, FormControlProps, InputLabel, MenuItem, Select } from "@mui/material";
import { useParticipant } from "@videosdk.live/react-sdk";

type VideoQualityButtonProps = FormControlProps & {
  participantId: string;
};

const VideoQualityButton: React.FC<VideoQualityButtonProps> = ({ participantId, ...props }) => {
  const { setQuality } = useParticipant(participantId);
  const [quality, _setQuality] = React.useState('high');

  const handleChange = (event: any) => {
    setQuality(event.target.value as ("low" | "med" | "high"));
    _setQuality(event.target.value);
  }

  return (
    <FormControl fullWidth {...props}>
      <InputLabel id="demo-simple-select-label">Video Quality</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={quality}
        label="Video Quality"
        onChange={handleChange}
      >
        <MenuItem value='low'>Low</MenuItem>
        <MenuItem value='med'>Medium</MenuItem>
        <MenuItem value='high'>High</MenuItem>
      </Select>
    </FormControl>
  );
}

export default VideoQualityButton;



