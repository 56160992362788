import React from 'react';
import {
  styled,
} from '@mui/material';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  width: '100%',
  textDecoration: 'none',
}));

export default StyledLink;