import React from "react";
import { Avatar, IconButton, IconButtonProps } from "@mui/material";
import { useMeeting } from "@videosdk.live/react-sdk";
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';

const enableColor = '#4caf50';
const disableColor = '#f44336';

const MicButton: React.FC<IconButtonProps> = (props) => {
  const { localMicOn, toggleMic } = useMeeting();
  return (
    <IconButton {...props} onClick={() => toggleMic()}>
      <Avatar
        sx={{ width: 64, height: 64, backgroundColor: localMicOn ? enableColor : disableColor }}
      >
        {localMicOn ? <MicIcon /> : <MicOffIcon />}
      </Avatar>
    </IconButton>
  );
}

export default MicButton;