import React from 'react';
import { Avatar, Box, BoxProps, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import BlurPaper from '../dumps/BlutPaper';
import { keyframes } from '@mui/system';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import { SubLevel } from '../../types/sub';
import { useSubLevels } from '../../hooks/useSubLevels';

const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
`;

type SubLevelSelectProps = BoxProps & {
  value?: SubLevel;
  onSelected: (box: SubLevel) => void;
}

const SubLevelSelect: React.FC<SubLevelSelectProps> = ({ onSelected, value, ...props }) => {
  const { subLevels } = useSubLevels();
  return (
    <Box {...props}>
      {subLevels.map((sl, index) => (
        <BlurPaper
          key={sl.id}
          duration={`${index * 0.1}s`}
          onClick={() => onSelected(sl)}
          sx={{
            cursor: 'pointer',
            padding: 1,
            border: '1px solid transparent',
            boxShadow: value?.id === sl.id ? '0 0 0 2px gold inset' : '0 0 0 1px #ccc inset',
            transition: 'box-shadow 0.3s ease-in-out',
            animation: value?.id === sl.id ? `${bounce} 0.3s` : 'none',
          }}
        >
          <ListItem secondaryAction={<WhatshotIcon htmlColor='gold' />}>
            <ListItemIcon>
              <Avatar src='/coin.webp' sx={{ width: 32, height: 32 }} />
            </ListItemIcon>
            <ListItemText primary={`Level ${sl.title}`} />
            <ListItemText primary={`${sl.price}`} />
          </ListItem>
        </BlurPaper>
      ))}
    </Box>
  );
};

export default SubLevelSelect;
