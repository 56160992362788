import cfg from '../config';
import { Sub, SubLevel } from '../types/sub';
import { getAccesToken } from './auth';

export const utrls = {
  Levels: '/api/v1/sub-levels',
  List: '/api/v1/subs',
  MyList(modelId?: string) {
    return modelId ? `/api/v1/account/subs?modelId=${modelId}` : '/api/v1/account/subs';
  }
};

export const levels = async (): Promise<SubLevel[]> => {
  const response = await fetch(`${cfg.apiUrl}${utrls.Levels}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return response.json()
}


export const list = async (): Promise<Sub[]> => {
  const response = await fetch(`${cfg.apiUrl}${utrls.List}`, {
    method: 'GET',
    headers: {
      "Authorization": `Bearer ${getAccesToken()}`,
      'Content-Type': 'application/json'
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json()
}

export type FilterProps = {
  modelId: string;
}

export const myList = async (query?: FilterProps): Promise<Sub[]> => {
  const url = query ?
    `${cfg.apiUrl}${utrls.MyList}?modelId=${query.modelId}` :
    `${cfg.apiUrl}${utrls.MyList}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      "Authorization": `Bearer ${getAccesToken()}`,
      'Content-Type': 'application/json'
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json()
}