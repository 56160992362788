import { useAuth } from './providers/AuthProvider';
import LoadingPage from '../pages/loading';
import ErrorPage from '../pages/error';
import { Route, RouteProps } from 'react-router-dom';

type PrivateRouteProps = {
  children: React.ReactNode;
}
const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { loaded, user } = useAuth();

  if (!loaded) {
    return <LoadingPage />;
  }

  if (!user) {
    return <ErrorPage>Unauthorized</ErrorPage>;
  }

  return children;
};

export default PrivateRoute;