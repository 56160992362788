import React from 'react';
import { Typography } from '@mui/material';
import MobilePage from '../components/layout/MobilePage';

const LoadingPage: React.FC = () => {
  return (
    <MobilePage hideBottomNav>
      <Typography alignSelf={'center'}>
        Loading...
      </Typography>
    </MobilePage>
  );
};

export default LoadingPage;