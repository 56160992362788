import React from 'react';
import { Typography } from '@mui/material';
import MobilePage from '../components/layout/MobilePage';

type ErrorPage = {
  children: React.ReactNode;
};
const ErrorPage: React.FC<ErrorPage> = ({ children }) => {
  return (
    <MobilePage hideBottomNav>
      <Typography alignSelf='center' justifySelf='center'>
        {children}
      </Typography>
    </MobilePage>
  );
};

export default ErrorPage;