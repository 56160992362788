import React from "react";
import { Sub } from "../types/sub";
import * as api from "../api/sub";
import useSWR from "swr";

export function useSubs() {
  const { data = [], isLoading } = useSWR<Sub[]>(api.utrls.MyList());
  return { subs: data, isLoading };
}

export function useMyModelSub(modelId?: string) {
  const { data = [], isLoading, mutate } = useSWR<Sub[]>(modelId ? () => api.utrls.MyList(modelId) : null);
  return {
    sub: data.length > 0 ? data[0] : null,
    isLoading,
    mutate
  };
}