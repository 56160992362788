import React, { useContext, createContext, useState, useEffect, useRef } from "react";

type Hardware = {
  id: string | null;
  label: string | null;
};

type MeetingAppContextType = {
  // states

  // raisedHandsParticipants: any[];
  selectedMic: Hardware;
  selectedWebcam: Hardware;
  selectedSpeaker: Hardware;
  sideBarMode: string | null;
  pipMode: boolean;
  isCameraPermissionAllowed: boolean | null;
  isMicrophonePermissionAllowed: boolean | null;

  // setters

  //setRaisedHandsParticipants: React.Dispatch<React.SetStateAction<any[]>>;
  setSelectedMic: React.Dispatch<React.SetStateAction<Hardware>>;
  setSelectedWebcam: React.Dispatch<React.SetStateAction<Hardware>>;
  setSelectedSpeaker: React.Dispatch<React.SetStateAction<Hardware>>;
  setSideBarMode: React.Dispatch<React.SetStateAction<string | null>>;
  setPipMode: React.Dispatch<React.SetStateAction<boolean>>;
  //useRaisedHandParticipants: () => { participantRaisedHand: (participantId: string) => void };
  setIsCameraPermissionAllowed: React.Dispatch<React.SetStateAction<boolean | null>>;
  setIsMicrophonePermissionAllowed: React.Dispatch<React.SetStateAction<boolean | null>>;
}

export const MeetingAppContext = createContext<MeetingAppContextType>({
  // states

  // raisedHandsParticipants: [],
  selectedMic: { id: null, label: null },
  selectedWebcam: { id: null, label: null },
  selectedSpeaker: { id: null, label: null },
  sideBarMode: null,
  pipMode: false,
  isCameraPermissionAllowed: null,
  isMicrophonePermissionAllowed: null,

  // setters

  //setRaisedHandsParticipants: () => {},
  setSelectedMic: () => {},
  setSelectedWebcam: () => {},
  setSelectedSpeaker: () => {},
  setSideBarMode: () => {},
  setPipMode: () => {},
  //useRaisedHandParticipants: () => ({ participantRaisedHand: () => {} }),
  setIsCameraPermissionAllowed: () => {},
  setIsMicrophonePermissionAllowed: () => {},
});

export const useMeetingApp = () => useContext(MeetingAppContext);

type MeetingAppProviderProps = {
  children: React.ReactNode;
};

export const MeetingAppProvider = ({ children }: MeetingAppProviderProps) => {
  const [selectedMic, setSelectedMic] = useState<Hardware>({ id: null, label: null });
  const [selectedWebcam, setSelectedWebcam] = useState<Hardware>({ id: null, label: null });
  const [selectedSpeaker, setSelectedSpeaker] = useState<Hardware>({ id: null, label: null });
  const [isCameraPermissionAllowed, setIsCameraPermissionAllowed] = useState<boolean | null>(null);
  const [isMicrophonePermissionAllowed, setIsMicrophonePermissionAllowed] = useState<boolean | null>(null);
  // const [raisedHandsParticipants, setRaisedHandsParticipants] = useState([]);
  const [sideBarMode, setSideBarMode] = useState<string | null>(null);
  const [pipMode, setPipMode] = useState(false);

  /*
  const useRaisedHandParticipants = () => {
    const raisedHandsParticipantsRef = useRef();

    const participantRaisedHand = (participantId: string) => {
      const raisedHandsParticipants = [...raisedHandsParticipantsRef.current];

      const newItem = { participantId, raisedHandOn: new Date().getTime() };

      const participantFound = raisedHandsParticipants.findIndex(
        ({ participantId: pID }) => pID === participantId
      );

      if (participantFound === -1) {
        raisedHandsParticipants.push(newItem);
      } else {
        raisedHandsParticipants[participantFound] = newItem;
      }

      setRaisedHandsParticipants(raisedHandsParticipants);
    };

    useEffect(() => {
      raisedHandsParticipantsRef.current = raisedHandsParticipants;
    }, [raisedHandsParticipants]);

    const _handleRemoveOld = () => {
      const raisedHandsParticipants = [...raisedHandsParticipantsRef.current];

      const now = new Date().getTime();

      const persisted = raisedHandsParticipants.filter(({ raisedHandOn }) => {
        return parseInt(raisedHandOn) + 15000 > parseInt(now);
      });

      if (raisedHandsParticipants.length !== persisted.length) {
        setRaisedHandsParticipants(persisted);
      }
    };

    useEffect(() => {
      const interval = setInterval(_handleRemoveOld, 1000);

      return () => {
        clearInterval(interval);
      };
    }, []);

    return { participantRaisedHand };
  };
  */

  return (
    <MeetingAppContext.Provider
      value={{
        // states

        // raisedHandsParticipants,
        selectedMic,
        selectedWebcam,
        selectedSpeaker,
        sideBarMode,
        pipMode,
        isCameraPermissionAllowed,
        isMicrophonePermissionAllowed,

        // setters

        //setRaisedHandsParticipants,
        setSelectedMic,
        setSelectedWebcam,
        setSelectedSpeaker,
        setSideBarMode,
        setPipMode,
        //useRaisedHandParticipants,
        setIsCameraPermissionAllowed,
        setIsMicrophonePermissionAllowed,
      }}
    >
      {children}
    </MeetingAppContext.Provider>
  );
};