import React from "react";
import { Avatar, IconButton, IconButtonProps } from "@mui/material";
import { useMeeting } from "@videosdk.live/react-sdk";
import { Phone } from "@mui/icons-material";

const goodRedColor = '#f44336';
const LeveButton: React.FC<IconButtonProps> = (props) => {
  const { leave } = useMeeting();
  return (
    <IconButton {...props} onClick={() => leave()}>
      <Avatar
        sx={{ width: 64, height: 64, backgroundColor: goodRedColor }}
      >
        <Phone />
      </Avatar>
    </IconButton>
  );
}

export default LeveButton;