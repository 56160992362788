import React from 'react';
import { Avatar, Box, Button, Typography } from '@mui/material';
import MobilePage from '../components/layout/MobilePage';
import AccountResetButton from '../components/containers/AccountResetButton';
import AccountName from '../components/containers/account/AccountBallance';
import SmallCoin from '../components/dumps/coins/SmallCoin';
import BigCoin from '../components/dumps/coins/BigCoin';
import BlurPaper from '../components/dumps/BlutPaper';
import { TonConnectButton } from '@tonconnect/ui-react';
import { Link } from 'react-router-dom';

const AccountPaper = BlurPaper;

const AdditionalPage: React.FC = () => {
  return (
    <MobilePage title='Balloons'>
      <BigCoin sx={{
        position: 'absolute',
        right: -30,
        top: '10%',
        width: 200,
        height: 200,
      }}/>
      <AccountPaper duration='0.25s'>
        <Avatar src='/logo128.webp' sx={{ width: 100, height: 100 }} />
        <AccountName variant='h6' />
        {/*
        <AccountResetButton color='error' size='large' variant='outlined' fullWidth>
          Reset Account
        </AccountResetButton>
        */}
      </AccountPaper>
      <SmallCoin sx={{
        position: 'absolute',
        left: 0,
        top: '5%',
        width: 120,
        height: 120,
      }}/>
      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' width='100%' gap={2} mt={2}>
        <Typography variant='h4'>Wallet</Typography>
        <TonConnectButton />
      </Box>
      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' width='100%' gap={2} mt={20}>
        <Link to='/history' style={{ width: '100%' }}>
          <Button color='primary' fullWidth>
            History
          </Button>
        </Link>
      </Box>
    </MobilePage>
  );
};

export default AdditionalPage;