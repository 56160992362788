import React from 'react';
import MobilePage from '../components/layout/MobilePage';
import Transferlist from '../components/containers/TransferList';


const HistoryPage: React.FC = () => {
  return (
    <MobilePage title='Balloons'>
      <Transferlist />
    </MobilePage>
  );
};

export default HistoryPage;