import React, { useEffect } from "react";
import { TokenBox } from "../types/token-box";
import * as api from "../api/token-box";
import useSWR from "swr";

type TokenBoxesHookParams = {
  disabled?: boolean;
};
export function useTokenBoxes(params?: TokenBoxesHookParams) {
  const { data: tokenBoxes = [], isLoading } = useSWR<TokenBox[]>(!params?.disabled ? api.utrls.List : null);
  return { tokenBoxes, isLoading };
}