import { Model, ModelData, Price } from '../types/model';
import cfg from '../config';

export const utrls = {
  List: '/api/v1/models',
  Get: (id: string) => `/api/v1/models/${id}`,
  GetByAlias: (alias: string) => `/api/v1/models/alias/${alias}`,
  Create: '/api/v1/models',
  Update: (id: string) => `/api/v1/models/${id}`,
  Delete: (id: string) => `/api/v1/models/${id}`,
  GetPrices: (id: string) => `/api/v1/models/${id}/prices`,
};

type ListParams = {
  companyId?: string;
}

export const list = async (params: ListParams): Promise<Model[]> => {
  const url = new URL(`${cfg.apiUrl}${utrls.List}`);
  if (params.companyId) {
    url.searchParams.append('companyId', params.companyId);
  }
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json()
}

export const get = async (id: string): Promise<Model> => {
  const response = await fetch(`${cfg.apiUrl}${utrls.Get(id)}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json()
}

export const getByAlias = async (alias: string): Promise<Model> => {
  const response = await fetch(`${cfg.apiUrl}${utrls.GetByAlias(alias)}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json()
}

export const create = async (model: ModelData): Promise<Model> => {
  const response = await fetch(`${cfg.apiUrl}${utrls.Create}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(model)
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json()
}

export const update = async (id: string, model: Partial<ModelData>): Promise<Model> => {
  const response = await fetch(`${cfg.apiUrl}${utrls.Update(id)}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(model)
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json()
}

export const remove = async (id: string): Promise<Model> => {
  const response = await fetch(`${cfg.apiUrl}${utrls.Delete(id)}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json()
}

export const getPrices = async (id: string): Promise<Price[]> => {
  const response = await fetch(`${cfg.apiUrl}${utrls.GetPrices(id)}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json()
}