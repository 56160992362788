import React from 'react';
import ModelList from '../components/containers/ModelList';
import MainMobilePage from '../components/layout/MainMobilePage';

const Home: React.FC = () => {
  return (
    <MainMobilePage title='Models'>
      <ModelList sx={{ width: '100%' }} />
    </MainMobilePage>
  );
};

export default Home;