import React from 'react';
import { Avatar, Typography, TypographyProps } from '@mui/material';
import { useAccount } from '../../providers/AccountProvider';

export type AccountBallanceProps = Omit<TypographyProps, 'children'> & {
  fontSize?: number;
}

const AccountBallance: React.FC<AccountBallanceProps> = ({ sx, fontSize, ...props }) => {
  const { ballance } = useAccount();

  const size = fontSize || 35;
  return (
    <Typography {...props} sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 1,
      fontSize: size,
      fontWeight: 700,
      ...sx
    }}>
      {`Tokens: ${ballance}`} <Avatar src='/coin.webp' sx={{ width: size, height: size }} />
    </Typography>
  );
};

export default AccountBallance;