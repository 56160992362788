import React from 'react';
import MobilePage from '../components/layout/MobilePage';
import SubsList from '../components/containers/SubsList';

const SubsPage: React.FC = () => {
  return (
    <MobilePage title='Subs'>
      <SubsList />
    </MobilePage>
  );
};

export default SubsPage;