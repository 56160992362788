import React from 'react';
import { Divider, List, ListItem, ListItemIcon, ListItemText, ListProps, Typography } from '@mui/material';
import BlurPaper from '../dumps/BlutPaper';
import useSWR from 'swr';
import { useModel } from '../providers/ModelProvider';
import MessageIcon from '@mui/icons-material/Message';
import VideocamIcon from '@mui/icons-material/Videocam';
import { Transfer, TransferType } from '../../types/transfer';
import ImageIcon from '@mui/icons-material/Image';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';

const PriceIcon = ({ type }: { type: TransferType }) => {
  switch (type) {
    case 'message':
      return <MessageIcon />;
    case 'content':
      return <ImageIcon />;
    case 'videocall':
      return <VideocamIcon />;
    case 'subscription':
      return <SubscriptionsIcon />;
    default:
      return <MessageIcon />;
  }
}

function useTranfers() {
  const { data = [] } = useSWR<Transfer[]>(`/api/v1/account/transfers`);
  return data;
}

type TransferlistProps = ListProps;
const Transferlist: React.FC<TransferlistProps> = (props) => {
  const prices = useTranfers();

  return (
    <List sx={{ width: '100%' }} {...props}>
      {prices.map((item) => (
        <ListItem key={item.id} dense>
          <ListItemIcon>
            <PriceIcon type={item.type} />
          </ListItemIcon>
          <ListItemText
            primary={item.type}
            secondary={item.price}
          />
          <ListItemText
            primary={item.amount}
            secondary={item.price * item.amount}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default Transferlist;