import React, { createContext, useCallback, useEffect, useState } from 'react';
// import { useInitData } from '@tma.js/sdk-react';
import { isTMA, retrieveLaunchParams  } from '@tma.js/sdk';
import { useParams } from 'react-router-dom';
import * as api from '../../api/auth';
import { User } from '../../types/user';

// "https://domain/bot/:alias"
// "https://domain/bot/:alias/meeting/:roomId"

const extractAliasFromCurrentUrl = () => {
  try {
    const url = window.location.href;
    const parsedUrl = new URL(url);
    const path = parsedUrl.pathname;
    const parts = path.split('/');
    const alias = parts[2];
    return alias;
  } catch (error) {
    console.error('Invalid URL:', error);
    return undefined;
  }
};

type AuthContextType = {
  loaded: boolean;
  user?: User;
}

const AuthContext = createContext<AuthContextType>({
  loaded: false,
});

export type AuthProviderProps = {
  children: React.ReactNode;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const alias = extractAliasFromCurrentUrl();

  const [loaded, setLoaded] = useState<boolean>(false);
  const [initDataRaw , setInitDataRaw] = useState<string>();
  const [user, setUser] = useState<User>();

  /*
  useEffect(() => {
    api.fakeLogin('66cb05ae0340d982c35ca612')
      .then(setUser)
      .catch(console.error)
      .finally(() => setLoaded(true));
  }, []);
  */
 
  useEffect(() => {
    isTMA()
      .then((isTMA) => {
        if (isTMA) {
          const { initDataRaw } = retrieveLaunchParams();
          setInitDataRaw(initDataRaw);
        } else {
          setLoaded(true);
        }
      })
      .catch((error) => {
        console.error('MyApp isTMA error', error);
        setLoaded(true);
      });
  }, []);

  useEffect(() => {
    initDataRaw && api.login(initDataRaw as any, alias)
      .then(setUser)
      .catch(console.error)
      .finally(() => setLoaded(true));
  }, [alias, initDataRaw]);

  return (
    <AuthContext.Provider value={{
      loaded,
      user,
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return React.useContext(AuthContext);
};