import { MeetingConsumer, MeetingProvider } from "@videosdk.live/react-sdk";
import React, { useCallback, useEffect, useState } from 'react';
import MobilePage from '../../../../components/layout/MobilePage';
import { MeetingAppProvider } from "../../../../components/meeting/MeetingAppProvider";
import { useNavigate, useParams } from "react-router-dom";
import * as api from '../../../../api/meeting';
import { Box, BoxProps, CircularProgress, Typography } from "@mui/material";
import MeetingView from "../../../../components/meeting/MeetingView";
import { ModelProvider } from "../../../../components/providers/ModelProvider";
import JoiningScreen from "../../../../components/meeting/JoiningScreen";

type Joining = BoxProps;
const Loading: React.FC<Joining> = (props) => {
  return (
    <Box width='100%' height='100vh' display='flex' justifyContent='center' alignItems='center' {...props}>
      <CircularProgress />
    </Box>
  );
}

const MeetingPage: React.FC = () => {
  const { alias, roomId } = useParams<{ roomId: string, alias: string }>();
  console.log('roomId', roomId, 'alias', alias);

  const [token, setToken] = useState<string | undefined>();
  useEffect(() => {
    if(!roomId) return;
    api.getMeetingToken(roomId)
      .then(setToken)
      .catch(console.error);
  }, [roomId]);

  const navigate = useNavigate();

  const [participantName, setParticipantName] = useState("Unknown");
  const [micOn, setMicOn] = useState(true);
  const [webcamOn, setWebcamOn] = useState(true);
  //const [customAudioStream, setCustomAudioStream] = useState<MediaStream | undefined>(undefined);
  //const [customVideoStream, setCustomVideoStream] = useState<MediaStream | undefined>(undefined);
  const [isMeetingStarted, setMeetingStarted] = useState(false);
  //const [isMeetingLeft, setIsMeetingLeft] = useState(false);

  const onMeetingLeave = useCallback(() => {
    navigate(`/bot/${alias}`);
  }, [alias]);

  if(!alias) {
    return <Typography variant="h6">Invalid Alias</Typography>;
  }
  if(!roomId) {
    return <Typography variant="h6">Invalid Room ID</Typography>;
  }

  return (
    <ModelProvider alias={alias}>
      <MeetingAppProvider>
        <MobilePage title='Meeting' hideBottomNav fullSize>
          {token && isMeetingStarted ? (
            <MeetingProvider
              config={{
                meetingId: roomId,
                micEnabled: micOn,
                webcamEnabled: webcamOn,
                name: participantName,
                multiStream: true,
                //customCameraVideoTrack: customVideoStream,
                //customMicrophoneAudioTrack: customAudioStream,
                debugMode: true,
              }}
              token={token}
              reinitialiseMeetingOnConfigChange={true}
              joinWithoutUserInteraction={true}
            >
              <MeetingConsumer>
                {() => (
                  <MeetingView meetingId={roomId} onMeetingLeave={onMeetingLeave} height='100vh' control />
                )}
              </MeetingConsumer>
            </MeetingProvider>
          ) : !isMeetingStarted ? (
            <JoiningScreen onJoin={() => setMeetingStarted(true)} height='100vh' />
          ) : (
            <Loading />
          )}
        </MobilePage>
      </MeetingAppProvider>
    </ModelProvider>
  );
};

export default MeetingPage;