import cfg from "../config";
import { getAccesToken } from "./auth";

export const getMeetingToken = async (roomId: string): Promise<string> => {
  const response = await fetch(`${cfg.apiUrl}/api/v1/meeting/get-token`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getAccesToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ roomId })
  });

  if (!response.ok) {
    throw new Error('Failed to get meeting token');
  }
  
  const data = await response.json();
  return data.token;
}

type Room = {
  roomId: string;
};

export const requestMeeting = async (modelId: string): Promise<Room> => {
  const url = `${cfg.apiUrl}/api/v1/account/meeting/request`;
  const response = await fetch(url.toString(), {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getAccesToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ modelId })
  });

  if (!response.ok) {
    throw new Error('Failed to request meeting');
  }
  
  const room = await response.json();
  return room as Room;
}