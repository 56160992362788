import cfg from "../config";
import { User } from "../types/user";

type AuthResponse = {
  user: User;
  access_token: string;
}

export const getAccesToken = () => {
  return localStorage.getItem('access_token');
}

const setAccesToken = (token: string) => {
  localStorage.setItem('access_token', token);
}
const clearAccesToken = () => {
  localStorage.removeItem('access_token');
}

export const login = async (initDataRaw: string, alias?: string): Promise<User> => {
  const url = alias ?
    `${cfg.apiUrl}/api/v1/auth/telegram?alias=${alias}` :
    `${cfg.apiUrl}/api/v1/auth/telegram`;
  const response = await fetch(url.toString(), {
    method: 'POST',
    headers: {
      Authorization: `tma ${initDataRaw}`,
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Failed to get ballance');
  }
  
  const data: AuthResponse = await response.json();
  setAccesToken(data.access_token);
  return data.user;
}

export const fakeLogin = async (id: string): Promise<User> => {
  const response = await fetch(`${cfg.apiUrl}/api/v1/auth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ id }),
  });

  if (!response.ok) {
    throw new Error('Failed to get ballance');
  }
  
  const data: AuthResponse = await response.json();
  setAccesToken(data.access_token);
  return data.user;
}