import React from "react";
import { Avatar, IconButton, IconButtonProps } from "@mui/material";
import { useMeeting } from "@videosdk.live/react-sdk";
import { Close } from "@mui/icons-material";

const goodRedColor = '#f44336';
const EndButton: React.FC<IconButtonProps> = (props) => {
  const { end } = useMeeting();
  return (
    <IconButton {...props} onClick={() => end()}>
      <Avatar
        sx={{ width: 64, height: 64, backgroundColor: goodRedColor }}
      >
        <Close />
      </Avatar>
    </IconButton>
  );
}

export default EndButton;