import React from 'react';
import { Avatar, AvatarProps } from '@mui/material';
import { useModel } from '../../providers/ModelProvider';

type ModelAvatarProps = Omit<AvatarProps, 'src'>;
const ModelAvatar: React.FC<ModelAvatarProps> = (props) => {
  const { model } = useModel();
  return (
    <Avatar src={model?.avatarUrl} sx={{ width: 100, height: 100 }} {...props} />
  );
}

export default ModelAvatar;