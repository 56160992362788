import React from 'react';
import MobilePage, { MobilePageProps } from './MobilePage';
import BlurPaper from '../dumps/BlutPaper';
import { Avatar, Box } from '@mui/material';
import AccountBallance from '../containers/account/AccountBallance';
import PayButton from '../containers/PayButton';

type MainMobilePageProps = Omit<MobilePageProps, 'header'>;
const MainMobilePage: React.FC<MainMobilePageProps> = ({ children, ...props }) => {
  return (
    <MobilePage
      header={(
        <BlurPaper duration='0.25s' sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box display='flex' flexDirection='row' alignItems='center' gap={2}>
            <Avatar src='/logo128.webp' sx={{ width: 50, height: 50 }} />
            <AccountBallance variant='h6' fontSize={20} />
          </Box>
          <PayButton variant='contained' fullWidth />
        </BlurPaper>
      )}
      {...props}
    >
      {children}
    </MobilePage>
  );
};

export default MainMobilePage;