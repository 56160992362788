import { Box, BoxProps, Typography } from "@mui/material";
import { useParticipant } from "@videosdk.live/react-sdk";
import React, { memo, useEffect, useMemo, useRef } from "react";
import ReactPlayer from "react-player";
import VideoQualityButton from "./VideoQualityButton";

type ParticipantViewProps = BoxProps & {
  participantId: string;
};

function ParticipantView({ participantId, ...props }: ParticipantViewProps) {
  const micRef = useRef<HTMLAudioElement>(null);
  const { webcamStream, micStream, webcamOn, micOn, isLocal, displayName } =
    useParticipant(participantId);


  const videoStream = useMemo(() => {
    if (webcamOn && webcamStream) {
      const mediaStream = new MediaStream();
      mediaStream.addTrack(webcamStream.track);
      return mediaStream;
    }
  }, [webcamStream, webcamOn]);

  useEffect(() => {
    if (micRef.current) {
      if (micOn && micStream) {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(micStream.track);

        micRef.current.srcObject = mediaStream;
        micRef.current
          .play()
          .catch((error) =>
            console.error("videoElem.current.play() failed", error)
          );
      } else {
        micRef.current.srcObject = null;
      }
    }
  }, [micStream, micOn]);

  return (
    <Box width='100%' height='100%' key={participantId} {...props}>
      <Box sx={{
        position: "absolute", top: 0, left: 0, zIndex: 1,
        flexDirection: "column",
        display: "flex",
        alignItems: "center",
        justifyContent: "center", 
        width: "100%",
        paddingLeft: 2,
        paddingRight: 2,
        gap: 1,
      }}>
        <Typography>
          Participant: {displayName} | Webcam: {webcamOn ? "ON" : "OFF"} | Mic:{" "}
          {micOn ? "ON" : "OFF"}
        </Typography>
        <VideoQualityButton participantId={participantId} />
      </Box>
      <audio ref={micRef} autoPlay muted={isLocal} />
      {webcamOn && (
        <ReactPlayer
          playsinline // very very imp prop
          pip={false}
          light={false}
          controls={false}
          muted={true}
          playing={true}
          url={videoStream}
          width="100%"
          height="100%"
          onError={(err) => {
            console.log(err, "participant video error");
          }}
        />
      )}
    </Box>
  );
}

export default React.memo(ParticipantView);