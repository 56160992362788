import { Avatar, Box, BoxProps, IconButton, IconButtonProps } from "@mui/material";
import React, { useState }  from "react";
import { JoinFull } from "@mui/icons-material";
import DropDown from "./components/DropDownMic";


const goodGreenColor = '#4caf50';
type JoinButtonProps = IconButtonProps;
const JoinButton: React.FC<JoinButtonProps> = (props) => {
  return (
    <IconButton {...props}>
      <Avatar
        sx={{ width: 64, height: 64, backgroundColor: goodGreenColor }}
      >
        <JoinFull />
      </Avatar>
    </IconButton>
  );
}

type JoiningScreenProps = BoxProps & {
  onJoin: () => void;
};

function JoiningScreen({ onJoin, ...props }: JoiningScreenProps) {
  const [{ webcams, mics, speakers }, setDevices] = useState({
    webcams: [],
    mics: [],
    speakers: [],
  });

  /*
  const changeMic = async (deviceId: string) => {
    if (micOn) {
      const currentAudioTrack = audioTrackRef.current;
      currentAudioTrack && currentAudioTrack.stop();
      const stream = await getAudioTrack({
        micId: deviceId,
      });
      setCustomAudioStream(stream);
      const audioTracks = stream?.getAudioTracks();
      const audioTrack = audioTracks.length ? audioTracks[0] : null;
      clearInterval(audioAnalyserIntervalRef.current);
      setAudioTrack(audioTrack);
    }
  };
  */

  return (
    <Box
      width='100%'
      height='100%'
      bgcolor="#000"
      alignItems="center"
      justifyContent="center"
      display="flex"
      flexDirection="column"
      gap={2}
      {...props}
    >
      {/*
      <DropDown
        mics={mics}
        changeMic={changeMic}
        customAudioStream={customAudioStream}
        audioTrack={audioTrack}
        micOn={micOn}
        didDeviceChange={didDeviceChange}
        setDidDeviceChange={setDidDeviceChange}
      />
      */}
      <JoinButton onClick={onJoin} />
    </Box>
  );
}

export default React.memo(JoiningScreen);