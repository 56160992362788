import React, { useCallback, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Button
} from '@mui/material';

import { Address, toNano } from "ton";
import { useAccount } from '../providers/AccountProvider';
import { toast } from "react-hot-toast";
import TokenBoxSelect from '../containers/TokenBoxSelect';
import { useSubLevels } from '../../hooks/useSubLevels';
import { SubLevel } from '../../types/sub';
import SubLevelSelect from '../containers/SubLevelSelect';

interface PaymentDialogProps {
  modelId: string;
  open: boolean;
  onSubscribed: () => void;
  onClose: () => void;
}


const SubscribeDialog: React.FC<PaymentDialogProps> = ({ modelId, open, onClose, onSubscribed }) => {

  const { subLevels } = useSubLevels();
  const [subLevel, setSubLevel] = useState<SubLevel | undefined>(subLevels.find((sl) => sl.default));

  useEffect(() => {
    if(subLevels.length === 0) return;
    setSubLevel(subLevels.find((sl) => sl.default) || subLevels[0]);
  }, [open, subLevels]);


  const { subscribe } = useAccount();

  const [waiting, setWaiting] = useState(false);
  const handleSubscribe = async () => {
    if(!subLevel) return;
    setWaiting(true);
    try {
      await subscribe({ modelId, levelId: subLevel.id });
      toast.success("Subscribed successfully");
      onSubscribed();
    } catch (e) {
      console.error(e);
      toast.error("Failed to subscribe");
    }
    setWaiting(false);
  }

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <DialogTitle>Subsribe</DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <SubLevelSelect
          value={subLevel}
          onSelected={(box) => setSubLevel(box)}
        />
        <Button onClick={onClose} color="error" variant='contained' fullWidth size='large' sx={{ mt: 4 }} disabled={waiting}>
          Cancel
        </Button>
        <Button
          onClick={handleSubscribe}
          color="primary" variant='contained' fullWidth size='large'
          disabled={waiting || !subLevel}
        >
          Subscribe
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default SubscribeDialog;