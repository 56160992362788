import React, { useState } from 'react';
import GoldButton, { GoldButtonProps } from '../dumps/GoldButton';
import { styled } from '@mui/material';

import PaymentDialog from '../dialogs/PaymentDialog';
import { useTonConnect } from '../../hooks/useTonConnect';

export type PayButtonProps = Omit<GoldButtonProps, 'onClick'>;

const Img = styled('img')({
  width: '16px',
  height: '16px',
  marginLeft: '4px',
  marginRight: '4px',
  transform: 'translateY(-1px)',
});

const PayButton: React.FC<PayButtonProps> = ({ disabled, ...props }) => {
  const [open, setOpen] = useState(false);
  // const { connected } = useTonConnect();

  return (
    <>
      <GoldButton
        size='large'
        {...props}
        onClick={() => setOpen(true)}
        disabled={disabled /*|| !connected enable after rm fake*/}
      >
        Buy <Img src='/coin.webp' alt='coin' /> Tokens
      </GoldButton>
      {
        open && (
          <PaymentDialog
            open={open}
            onClose={() => setOpen(false)}
            onPayment={() => setOpen(false)}
          />
        )
      }
    </>
  );
};

export default PayButton;