import React, { useEffect } from 'react'
//import logo from './logo.svg';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from './components/providers/ThemeProvider';
import SWRProvider from './components/providers/SWRProvider';
import { AccountProvider } from './components/providers/AccountProvider';
// import { SDKProvider } from '@tma.js/sdk-react';
import { isTMA, retrieveLaunchParams, postEvent, setDebug } from '@tma.js/sdk';
import StartPage from './pages';
import AdditionalPage from './pages/additional';
import ModelPage from './pages/model/[alias]';
import BotPAge from './pages/bot/[alias]';
import SubsPage from './pages/subs';
import MeetingRoom from './pages/bot/[alias]/meeting/[roomId]';
import MainMeetingPage from './pages/control/[alias]/meeting/[roomId]';
import { AuthProvider } from './components/providers/AuthProvider';
import PrivateRoute from './components/PrivateRoute.';
import HistoryPage from './pages/history';

function App() {
  useEffect(() => {
    //setDebug(true)
    isTMA().then((isTMA) => {
      if (isTMA) {
        const lp = retrieveLaunchParams();

        // Some versions of Telegram don't need the classes above.
        if (['macos', 'tdesktop', 'weba', 'web', 'webk'].includes(lp.platform)) {
          return;
        }

        // Expand the application.
        postEvent('web_app_expand');
      }
    }).catch((error) => {
      console.error('MyApp isTMA error', error);
    });
  }, []);

  return (
    <Router>
      <SWRProvider>
        <ThemeProvider>
          <AuthProvider>
            <AccountProvider>
              {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
              <CssBaseline />
              {/* @ts-ignore */}
              <Routes>
                <Route path="/" element={<PrivateRoute><StartPage /></PrivateRoute>} />
                <Route path="/model/:alias" element={<PrivateRoute><ModelPage /></PrivateRoute>} />
                <Route path="/bot/:alias" element={<PrivateRoute><BotPAge /></PrivateRoute>} />
                <Route path="/additional" element={<PrivateRoute><AdditionalPage /></PrivateRoute>} />
                <Route path="/subs" element={<PrivateRoute><SubsPage /></PrivateRoute>} />
                <Route path="/history" element={<PrivateRoute><HistoryPage /></PrivateRoute>} />
                <Route path="/bot/:alias/meeting/:roomId" element={<PrivateRoute><MeetingRoom /></PrivateRoute>} />
                <Route path="/control/:alias/meeting/:roomId" element={<MainMeetingPage />} />
              </Routes>
            </AccountProvider>
          </AuthProvider>
        </ThemeProvider>
      </SWRProvider>
    </Router>
  );
}

export default App;
