import React from "react";
import ModelList from "./ModelList";
import useSWR from "swr";
import { Sub } from "../../types/sub";
import * as api from "../../api/sub";
import BlurPaper from "../dumps/BlutPaper";
import StyledLink from "../dumps/StyledLink";
import { Avatar, ListItem, ListItemText, Typography } from "@mui/material";
import { ArrowForwardSharp } from "@mui/icons-material";
import { useSubs } from "../../hooks/useSubs";


const SubsList: React.FC = () => {
  const { subs, isLoading } = useSubs();
  if(subs.length === 0 && !isLoading) {
    return (
      <>
        <Typography variant='h6' fontWeight={700} pt={2} pb={2}>
          No subscriptions found
        </Typography>
        <BlurPaper duration={`0.2s`}>
          <StyledLink to='/' replace>
            <ListItem secondaryAction={
              <Avatar>
                <ArrowForwardSharp />
              </Avatar>
            }>
              <ListItemText
                primary={(
                  <Typography variant='h6' fontWeight={700}>
                    Find a model to subscribe
                  </Typography>
                )}
              />
            </ListItem>
          </StyledLink>
        </BlurPaper>
      </>
    )
  }
  return (
    <ModelList filter={subs.map((sub) => sub.modelId)} />
  );
};

export default SubsList;