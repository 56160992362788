import React from "react";
import { Avatar, IconButton, IconButtonProps } from "@mui/material";
import { useMeeting } from "@videosdk.live/react-sdk";
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';

const enableColor = '#4caf50';
const disableColor = '#f44336';

const CameraButton: React.FC<IconButtonProps> = (props) => {
  const { localWebcamOn, toggleWebcam } = useMeeting();
  return (
    <IconButton {...props} onClick={() => toggleWebcam()}>
      <Avatar
        sx={{ width: 64, height: 64, backgroundColor: localWebcamOn ? enableColor : disableColor }}
      >
        {localWebcamOn ? <VideocamIcon /> : <VideocamOffIcon />}
      </Avatar>
    </IconButton>
  );
}

export default CameraButton;