import React, { useState } from 'react';
import useSWR from 'swr';
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ListProps,
  Typography,
} from '@mui/material';
import * as api from '../../api/model';
import { Model } from '../../types/model';
import BlurPaper from '../dumps/BlutPaper';
import { ArrowForwardSharp } from '@mui/icons-material';
import StyledLink from '../dumps/StyledLink';

function useModels(filter?: string[]) {
  const { data = [] } = useSWR<Model[]>(api.utrls.List);
  return filter ? 
    data.filter((model) => filter.includes(model.id)) :
    data.filter((model) => model.isActivated);
}

type ModelListProps = ListProps & {
  filter?: string[];
}
const ModelList: React.FC<ModelListProps> = ({ filter, sx, ...props }: ModelListProps) => {
  const models = useModels(filter);
  return (
    <List sx={{ width: '100%', ...sx }}  {...props}>
      {models.map((model, index) => (
        <BlurPaper key={model.id} duration={`${index * 0.1}s`}>
          <StyledLink to={`/model/${model.alias}`}>
            <ListItem secondaryAction={
              <Avatar>
                <ArrowForwardSharp />
              </Avatar>
            }>
              <ListItemAvatar>
                <Avatar src={model.avatarUrl} alt={model.name} />
              </ListItemAvatar>
              <ListItemText
                primary={(
                  <Typography variant='h6' fontWeight={700}>
                    {model.name}
                  </Typography>
                )}
              />
            </ListItem>
          </StyledLink>
        </BlurPaper>
      ))}
    </List>
  );
};

export default React.memo(ModelList);
