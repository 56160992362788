import { Avatar, Box, BoxProps, Button, IconButton, IconButtonProps, Typography } from "@mui/material";
import { useMeeting, useParticipant } from "@videosdk.live/react-sdk";
import React, { memo, useEffect, useMemo, useState } from "react";
import ParticipantView from "./components/ParticipantView";
import { JoinFull, Phone } from "@mui/icons-material";
import LeveButton from "./components/LeveButton";
import MicButton from "./components/MicButton";
import CameraButton from "./components/CameraButton";
import EndButton from "./components/EndButton";

const ParticipantMicStream = memo(({ participantId }: { participantId: string }) => {
  // Individual hook for each participant
  const { micStream } = useParticipant(participantId);

  useEffect(() => {

    if (micStream) {
      const mediaStream = new MediaStream();
      mediaStream.addTrack(micStream.track);

      const audioElement = new Audio();
      audioElement.srcObject = mediaStream;
      audioElement.play();

    }
  }, [micStream, participantId]); 

  return null;
});

const goodRedColor = '#f44336';
type StopButtonProps = IconButtonProps;
const StopButton: React.FC<StopButtonProps> = (props) => {
  return (
    <IconButton {...props}>
      <Avatar
        sx={{ width: 64, height: 64, backgroundColor: goodRedColor }}
      >
        <Phone />
      </Avatar>
    </IconButton>
  );
}

const goodGreenColor = '#4caf50';
type JoinButtonProps = IconButtonProps;
const JoinButton: React.FC<JoinButtonProps> = (props) => {
  return (
    <IconButton {...props}>
      <Avatar
        sx={{ width: 64, height: 64, backgroundColor: goodGreenColor }}
      >
        <JoinFull />
      </Avatar>
    </IconButton>
  );
}

const LocalParticipantView = ({ audio }: { audio?: boolean }) => {
  const { localParticipant } = useMeeting();
  return (
    <>
      <ParticipantView participantId={localParticipant.id} />;
      {/*audio && <ParticipantMicStream participantId={localParticipant.id} /> */}
    </>
  )
}

export function ParticipantsViewer() {
  const { participants, localParticipant } = useMeeting();
  const remoteParticipants = useMemo(() => {
    if(!localParticipant) {
      return [];
    }
    const localParticipantId = localParticipant.id;
    return Array.from(participants.keys()).filter((id) => id !== localParticipantId);
  }, [participants, localParticipant]);

  const showLocalParticipant = !!localParticipant && remoteParticipants.length === 0;
  console.log('remoteParticipants', remoteParticipants, 'showLocalParticipant', showLocalParticipant);

  return (
    <>
      {remoteParticipants.map((participantId) => (
        <ParticipantView key={participantId} participantId={participantId} />
      ))}
      {
        showLocalParticipant && (
          <LocalParticipantView />
        )
      }
    </>
  );
    
  // <ParticipantMicStream key={`${participantId}_mic`} participantId={participantId} />
}

type MeetingViewProps = BoxProps & {
  control?: boolean;
  meetingId: string;
  onMeetingLeave: () => void;
};

function MeetingView({ control, meetingId, onMeetingLeave, ...props }: MeetingViewProps) {
  const [joined, setJoined] = useState<string | null>('JOINING');
  const { join } = useMeeting();
  const mMeeting = useMeeting({
    onMeetingJoined: () => {
      setJoined("JOINED");
    },
    onMeetingLeft: () => {
      onMeetingLeave();
    },
    onParticipantJoined: (participant) => {
      participant && participant.setQuality("high");
      console.log('participant joined', participant);
    },
    onParticipantLeft: (participant) => {
      console.log('participant left', participant);
    },
  });

  const joinMeeting = () => {
    setJoined("JOINING");
    join();
  };
  useEffect(() => {
    joinMeeting();
  }, []);
  
  return (
    <Box
      width='100%'
      height='100%'
      bgcolor="#000"
      alignItems="center"
      justifyContent="center"
      display="flex"
      flexDirection="column"
      gap={2}
      {...props}
    >
      {joined == "JOINED" ? (
        <Box width='100%' height='100%'>
          <ParticipantsViewer />
          <Box sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 2,
          }}>
            <CameraButton />
            <MicButton />
            <LeveButton />
            { control && <EndButton />}
          </Box>
        </Box>
      ) : joined == "JOINING" ? (
        <Typography variant="h6">Joining the room...</Typography>
      ) : (
        <StopButton onClick={() => onMeetingLeave()} sx={{ paddingTop: 4 }} />
      )}
    </Box>
  );
}

export default React.memo(MeetingView);