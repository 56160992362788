import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/system';
import { bounceAnimationCss } from '../../lib/animations';

type AddProps = {
  duration?: string;
  startcolor?: string;
  endcolor?: string;
};

export type GoldButtonProps = ButtonProps & AddProps;

const GoldButton = styled(Button)<AddProps>(({ theme, duration = '0.15s', startcolor = '#FFB800', endcolor = '#FF6B00' }) => ({
  background: `linear-gradient(90deg, ${startcolor} 0%, ${endcolor} 100%)`,
  minHeight: '56px',
  fontSize: '16px',
  fontWeight: 600,
  '&:hover': {
    background: `linear-gradient(90deg, ${startcolor} 0%, ${endcolor} 100%)`,
  },
  ...bounceAnimationCss(duration)
}));

export default GoldButton;