import React, { useState } from 'react';
import GoldButton, { GoldButtonProps } from '../../dumps/GoldButton';
import { useModel } from '../../providers/ModelProvider';
import SubscribeDialog from '../../dialogs/SubscribeDialog';
import { useMyModelSub } from '../../../hooks/useSubs';
import toast from 'react-hot-toast';
import { useAccount } from '../../providers/AccountProvider';
import { Cancel } from '@mui/icons-material';

export type SubscribeButtonProps = Omit<GoldButtonProps, 'onClick'>;

const startColor = `#FF00FF`;
const endColor = `#800080`;
const SubscribeButton: React.FC<SubscribeButtonProps> = ({ disabled, ...props }) => {
  const [open, setOpen] = useState(false);
  const { modelId } = useModel();
  const { sub, mutate } = useMyModelSub(modelId)
  const { unsubscribe } = useAccount();

  const handleSubscribeClick = () => {
    setOpen(true);
  }

  const handleCancelSubscribe = async () => {
    if (!sub) return;
    try {
      await toast.promise(unsubscribe({ modelId: sub.modelId }), {
        loading: 'Cancelling...',
        success: 'Cancelled',
        error: 'Failed to cancel'
      });
      mutate();
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <>
      <GoldButton
        variant='contained'
        fullWidth
        size='large'
        {...props}
        startcolor={sub ? startColor : undefined}
        endcolor={sub ? endColor : undefined}
        onClick={sub ? handleCancelSubscribe : handleSubscribeClick}
        disabled={disabled || !modelId}
        startIcon={sub ? <Cancel /> : undefined}
      >
        {sub ? 'Unsubscribe' : 'Subscribe'}
      </GoldButton>
      {
        open && modelId && (
          <SubscribeDialog
            open={open}
            modelId={modelId}
            onClose={() => setOpen(false)}
            onSubscribed={() => {
              setOpen(false);
              mutate();
            }}
          />
        )
      }
    </>
  );
};

export default SubscribeButton;