import React, { useCallback, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Button
} from '@mui/material';

import { Address, toNano } from "ton";
import { useAccount } from '../providers/AccountProvider';
import { toast } from "react-hot-toast";
import { useTonConnect } from "../../hooks/useTonConnect";
import TokenBoxSelect from '../containers/TokenBoxSelect';
import { TokenBox } from '../../types/token-box';
import { useTokenBoxes } from '../../hooks/useTokenBoxes';
import { useTonRecipientAddress } from '../../hooks/useTonRecipientAddress';
// https://github.com/ton-community/twa-template/blob/main/src/components/TransferTon.tsx
// https://docs.ton.org/develop/dapps/ton-connect/react

interface PaymentDialogProps {
  open: boolean;
  onPayment: (amount: string, recipient: string) => void;
  onClose: () => void;
}


const PaymentDialog: React.FC<PaymentDialogProps> = ({ open, onClose, onPayment }) => {
  const { wallet, sender, connected } = useTonConnect();

  const { tokenBoxes } = useTokenBoxes();
  const [tokenBox, setTokenBox] = useState<TokenBox | undefined>(tokenBoxes.find((box) => box.default));

  useEffect(() => {
    if(tokenBoxes.length === 0) return;
    setTokenBox(tokenBoxes.find((box) => box.default) || tokenBoxes[0]);
  }, [open, tokenBoxes]);

  const tonRecipient = useTonRecipientAddress();

  const { payment } = useAccount();

  const [waiting, setWaiting] = useState(false);
  const handlePay = async () => {
    if (!tokenBox) return;
    try {
      setWaiting(true);
      await toast.promise(payment({ walletId: 'fake', tokenBoxId: tokenBox.id, channel: 'fake' }), {
        loading: 'Paying...',
        success: 'Paid!',
        error: 'Failed to pay',
      });
      const tonAmount = tokenBox.price;
      onPayment(tonAmount, tonRecipient);
    } catch (error: any) {
      toast.error('Failed to pay ' + error.message);
      console.error(error);
    } finally {
      setWaiting(false);
    }
  }

  const handleTransfer = async () => {
    if (!sender) return;
    if(!tokenBox) return;
    if(!wallet) return;

    try {
      setWaiting(true);
      const tonAmount = tokenBox.price;
      const send = async () => {
        await sender.send({ to: Address.parse(tonRecipient), value: toNano(tonAmount) });
        await payment({ walletId: wallet, tokenBoxId: tokenBox.id, channel: 'ton' });
      }
      await toast.promise(send(), {
        loading: 'Paying...',
        success: 'Paid!',
        error: 'Failed to pay',
      });

      onPayment(tonAmount, tonRecipient);
    } catch (error: any) {
      toast.error('Failed to transfer ' + error.message);
      console.error(error);
    } finally {
      setWaiting(false);
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <DialogTitle>Payment</DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {/*
        <Typography>Transfer TON</Typography>
        <TextField
          label="Amount"
          type="number"
          value={tonAmount}
          fullWidth
          onChange={(e) => setTonAmount(e.target.value)}
        />
        <TextField
          label="To"
          value={tonRecipient}
          fullWidth
          disabled
          onChange={(e) => setTonRecipient(e.target.value)}
        />
        */}
        <TokenBoxSelect
          value={tokenBox}
          onSelected={(box) => setTokenBox(box)}
        />
        <Button onClick={onClose} color="error" variant='contained' fullWidth size='large' sx={{ mt: 4 }} disabled={waiting}>
          Cancel
        </Button>
        <Button
          onClick={handlePay}
          color="primary" variant='contained' fullWidth size='large'
          disabled={waiting || !tokenBox}
        >
          Fake Transfer
        </Button>
        <Button
          color="primary" variant='contained' fullWidth size='large'
          disabled={waiting || !connected || !wallet || !tokenBox}
          onClick={handleTransfer}
        >
          Transfer
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default PaymentDialog;