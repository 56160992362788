import React, { useCallback, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Stack, Typography } from "@mui/material";
import GoldButton from "../dumps/GoldButton";

export type RequestCallDialogProps = DialogProps & {
  onRequested: () => void;
  onCancel: () => void;
}

const RequestCallDialog: React.FC<RequestCallDialogProps> = ({ onRequested, onCancel, ...props }) => {
  return (
    <Dialog fullScreen sx={{  }} {...props}>
      <DialogTitle>
        <Typography variant='h4' align='center'>
          Video Call
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography align='center'>
          Are you sure you want to start a video call with this model?
        </Typography>
        <Typography align='center'>
          It will cost you 1500 tokens per 15 minutes.
        </Typography>
      </DialogContent>
      <Stack spacing={2} width='90%' alignSelf='center' pb={2}>
        <GoldButton
          variant='contained'
          onClick={onRequested}
        >
          Request Call
        </GoldButton>
        <Button
          variant='contained'
          onClick={onCancel}
          sx={{
            minHeight: '56px',
            fontSize: '16px',
            fontWeight: 600,
          }}
        >
          Cancel
        </Button>
      </Stack>
    </Dialog>
  );
}

export default React.memo(RequestCallDialog);